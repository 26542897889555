import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { BehaviorSubject, interval, Subject, Subscription, timer } from 'rxjs';
import { delay, delayWhen, filter, map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AttachmentMessageContent, ButtonContent, Message, MessageType } from '../../model/message';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { LoggerService } from '../../services/logger/logger.service';
import { DisplayService } from '../../services/display.service';

const animationFPS = 60;

const layoutGap = 7.5;

/**
 * 1) Preload pop trigger message off-screen directly into the DOM (to avoid unwanted reloads when browser cache is turned off)
 * 2) Emits an event only when fully loaded.
 * 3) Wait for the container to start the animation.
 * 4) Emits an event every "animationFPS" frame to tell the container its height changed.
 */
@Component({
  selector: 'app-pop-trigger-message',
  templateUrl: './pop-trigger-message.component.html',
  styleUrls: ['./pop-trigger-message.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, ExtendedModule, NgStyle, NgSwitch, NgSwitchCase, NgFor, SafeUrlPipe],
})
export class PopTriggerMessageComponent implements OnDestroy {
  private subs: Subscription[] = [];

  // @see Don't forget to synchronize with SCSS.
  animationLength = 0.35;
  animationDelay = 1.75;

  config$ = new BehaviorSubject<{
    message: any; // TODO type correctly when using multiple pop trigger message components
    messageIndex: number;
    canAnimate: boolean;
  }>(null);

  animate = false;
  itemHoverInd = -1;
  messageHoverInd = -1;
  animationDone$: Subject<void> = new Subject();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  destinationHeight = 0;
  contentHeight = 0;
  contentWidth = 0;
  measured = false;

  get loading() {
    return this.loading$.getValue();
  }
  set loading(value) {
    this.loading$.next(value);
  }

  @Input()
  get config() {
    return this.config$.getValue();
  }

  set config(value) {
    this.config$.next(value);
  }

  @Output() loadingEnd = new EventEmitter<{ destinationHeight: number }>();
  @Output() reply = new EventEmitter<{ message: Message; item: ButtonContent }>();
  @Output() expand = new EventEmitter<{ currentHeight: number }>();
  @Output() animationStart = new EventEmitter<{
    message: Message;
    destinationHeight: number;
    destinationWidth: number;
  }>();

  @HostBinding('style.height') currentHeight = '0px';
  @ViewChild('popMessageWrapper') popMessageWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('popMessage') popMessage: ElementRef<HTMLDivElement>;
  @ViewChild('popMessageContent') popMessageContent: ElementRef<HTMLDivElement>;

  constructor(
    private logger: LoggerService,
    public displayService: DisplayService,
  ) {
    // Terminate loading on pop trigger messages that do not require to be loaded.
    this.subs.push(
      this.config$
        .pipe(
          filter((config) => !!config),
          filter(({ message }) => !!message),
          tap(({ message }) => {
            if (message.type !== MessageType.IMAGE && message.type !== MessageType.VIDEO) {
              this.loading = false;
            }
          }),
        )
        .subscribe(),
    );

    // Emit an event when loaded, even when the content do not requires a real loading phase.
    this.subs.push(
      this.loading$
        .pipe(
          filter((loading) => !loading),
          take(1),
          delay(500), // Let some time to text and video to automatically adjust its size after fully loaded
          tap(() => {
            this.contentHeight = this.popMessage?.nativeElement?.getBoundingClientRect?.()?.height + layoutGap;

            // INFO: this is required to prevent mobile users from having the iframe take the width of a video or image trigger, which are not displayed on mobile
            if (
              (this.displayService.isMobile &&
                this.config.message.type !== MessageType.VIDEO &&
                this.config.message.type !== MessageType.IMAGE) ||
              !this.displayService.isMobile
            ) {
              const contentPaddingInPx = parseInt(getComputedStyle(this.popMessage?.nativeElement)?.padding) ?? -1;
              const contentBorderWidthInPx =
                parseInt(getComputedStyle(this.popMessage?.nativeElement)?.borderWidth) ?? -1;
              const mobileModeMarginInPx = 8;

              if (contentPaddingInPx === -1 || contentBorderWidthInPx === -1) {
                this.logger.error(
                  "Couldn't get all the required CSS properties to calculate the iframe's desired width",
                );
              }

              this.contentWidth =
                this.popMessageContent?.nativeElement?.getBoundingClientRect?.()?.width +
                contentPaddingInPx * 2 +
                contentBorderWidthInPx * 2 +
                (this.displayService.isMobile ? mobileModeMarginInPx : 0);
            }
          }),
          tap(() => (this.measured = true)),
          tap(() => this.loadingEnd.emit({ destinationHeight: this.contentHeight })),
        )
        .subscribe(),
    );

    // Play the animation.
    this.subs.push(
      this.config$
        .pipe(
          filter((config) => !!config),
          skipWhile((config) => !config?.canAnimate),
          delayWhen(() => timer(this.config.messageIndex * (this.animationLength + this.animationDelay) * 1000)),
          switchMap(() => this.animateIt()),
        )
        .subscribe(),
    );
  }

  attachmentSrc(message: Message) {
    const content = message.content as AttachmentMessageContent;
    switch (content.contentType) {
      case 'pdf':
        return 'assets/file-icon-pdf.png';
      case null:
      default:
        return 'assets/file-icon-unknown.png';
    }
  }

  getButtonsItemStyle(itemInd: number) {
    if (itemInd === this.itemHoverInd) {
      return this.displayService.chatStyle.buttonsStyle.hoveredItem;
    }
    return this.displayService.chatStyle.buttonsStyle.item;
  }

  getButtonsItemContentStyle(itemInd: number) {
    if (itemInd === this.itemHoverInd) {
      return this.displayService.chatStyle.buttonsStyle.hoveredItemContent;
    }
    return this.displayService.chatStyle.buttonsStyle.itemContent;
  }

  clickButton(message: Message, item: ButtonContent) {
    this.reply.emit({ message, item });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub?.unsubscribe?.());
  }

  onLoad() {
    this.loading = false;
  }

  /**
   * Synchronize CSS animation and Angular rendering.
   */
  private animateIt() {
    this.animate = true;
    this.destinationHeight = this.contentHeight;
    this.subs.push(
      timer(this.animationDelay * 1000)
        .pipe(
          tap(() =>
            this.animationStart.emit({
              message: this.config.message,
              destinationHeight: this.contentHeight,
              destinationWidth: this.contentWidth,
            }),
          ),
        )
        .subscribe(),
    );

    return interval(1000 / animationFPS).pipe(
      takeUntil(timer((this.animationDelay + this.animationLength) * 1000 * 1.25)),
      map(() => {
        this.currentHeight = `${this.contentHeight}px`;
      }),
    );
  }
}
