<div fxFlex="grow" class="darken-blur-backdrop" *ngIf="enableDarkenAndBlurBackdrop"></div>
<ng-container [ngSwitch]="chatMode">
  <div [@slideUpDownWidget]="chatAnimation" [ngClass]="[chatMode + '-mode']">
    <ng-container *ngSwitchCase="'chat'">
      <div class="main" fxLayout="column" [checkFiles]="checkFiles" (appDropZone)="onDrop($event)">
        <div fxFlex="grow" id="dropzone" class="dropzone"></div>
        <div
          fxFlex="grow"
          class="waiting-backdrop-back"
          [ngClass]="{ 'hide-backdrop': !selfCareContactFormWaitingReply }"
        >
          <div class="waiting-backdrop">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div fxFlex="nogrow" class="top-container" [ngStyle]="displayService.chatStyle.bgStyle">
          <div
            class="top"
            [ngClass]="{ hide: chatHidden, expandHeader: expandHeader && chatMode !== 'form' }"
            [ngStyle]="displayService.chatStyle.headerStyle"
          >
            <div class="headerText">
              <h3
                *ngIf="!(session && session.widgetTitle)"
                [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
              >
                {{ displayService.chatStyle.headerText.title || '' }}
              </h3>
              <h3
                *ngIf="session && session.widgetTitle"
                [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
              >
                {{ session.widgetTitle || '' }}
              </h3>
            </div>
            <div class="headerButtons">
              <rmx-icon
                name="home-2-line"
                class="s-xl"
                *ngIf="!expandHeader"
                [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
                (click)="selfCareResetAsk()"
              ></rmx-icon>

              <rmx-icon
                name="subtract-line"
                class="s-xl"
                *ngIf="!displayService.freeDivMode && !displayService.isStandalone"
                [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
                (click)="toggleChatStatus(true)"
              ></rmx-icon>
            </div>
          </div>
        </div>

        <div
          fxFlex="nogrow"
          *ngIf="session && session.selfCareTitle"
          class="self-care-thread-title"
          [ngStyle]="{ display: expandHeader ? 'none' : 'block' }"
        >
          <p>{{ session.selfCareTitle }}</p>
        </div>

        <ng-container *ngIf="expandHeader">
          <div fxFlex="nogrow" fxLayout="column" fxLayoutAlign="start center">
            <div
              id="welcome-image"
              *ngIf="displayService.chatStyle.enableHeaderImage"
              [fxFlex]="'0 1 ' + (displayService.fullHeight ? '220' : '170') + 'px'"
              [ngStyle]="displayService.chatStyle.welcomeHeaderStyle"
            ></div>
            <span
              class="subtitle"
              [style.margin]="displayService.fullHeight ? '20px 0 12px' : '10px 0 2px'"
              *ngIf="
                displayService.chatStyle.headerText && !!displayService.chatStyle.headerText.text
              "
            >
              {{ displayService.chatStyle.headerText.text }}
            </span>
          </div>
        </ng-container>

        <ng-scrollbar
          fxFlex="grow"
          fxFill
          class="chat"
          [ngStyle]="displayService.chatStyle.bgStyle"
          [ngClass]="{ hide: chatHidden }"
        >
          <div fxLayout="column" fxLayoutGap="2.5px" class="chat-container">
            <div
              fxFlex="nogrow"
              class="chat-message-container"
              *ngFor="
                let message of shownMessages;
                let i = index;
                let isFirst = first;
                let isLast = last;
                trackBy: trackMessageBy
              "
              [ngStyle]="{
                'padding-top':
                  !isFirst && (message.type === 'buttons' || !precededBySameAuthor(i))
                    ? '7.5px'
                    : null
              }"
              [ngClass]="{ bottomSticky: bottomSticky(i), messageFlexGrow: messageFlexGrow(i) }"
              (click)="focusMessage(i)"
              (clickOutside)="unFocusMessage(i)"
            >
              <app-message
                @slideUpMessage
                [widget]="this"
                [message]="message"
                [messageLength]="message?.content?.text?.length ?? 0"
                [displayAvatar]="displayAvatar(i)"
                [displayName]="displayName(i)"
                [focused]="this.focusMessageIndex === i"
                [precededBySameAuthor]="precededBySameAuthor(i)"
                [followedBySameAuthor]="followedBySameAuthor(i)"
                [selfCareEnabled]="selfCareEnabled"
                [chatMode]="chatMode"
                [isLastMessage]="isLast"
                [enableSchemaSatisfaction]="
                  displayService.chatStyle.enableSchemaSatisfaction &&
                  isLast &&
                  message.webchatMessageId
                "
                (schemaSatisfaction)="setSchemaSatisfaction($event, i)"
                (openWebview)="showWebview($event)"
                (streamed)="streamed()"
              ></app-message>
            </div>
            <div
              *ngIf="(session && session.agentIsTyping && chatMode !== 'form') || hasStreaming"
              class="is-typing-container"
            >
              <div class="is-typing">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </ng-scrollbar>

        <div
          fxFlex="nogrow"
          class="self-care-contact-form-action-buttons"
          *ngIf="
            selfCareEnabled &&
            session &&
            session.selfCareActionButtonsEnabled &&
            session.selfCareActionButtonsDisplayed
          "
        >
          <div
            *ngIf="session && session.selfCareAskButtonEnabled"
            (mouseover)="hoverActionButton = 'selfCareAskButton'"
            (mouseleave)="hoverActionButton = 'none'"
            (click)="selfCareAskQuestion()"
            [ngStyle]="getActionButtonStyle('selfCareAskButton')"
            class="action-button"
          >
            <div [ngStyle]="getActionButtonContentStyle('selfCareAskButton')">
              {{ this.selfCareAskButtonText }}
            </div>
          </div>
        </div>

        <div
          fxFlex="nogrow"
          fxLayout="column"
          fxLayoutGap="0.625em"
          class="bottom"
          [ngStyle]="displayService.chatStyle.bgStyle"
          [ngClass]="{ hide: chatHidden }"
        >
          <div
            fxFlex="nogrow"
            class="queue-indicator"
            *ngIf="!!thirdPartyQueuePosition"
          >
            {{ 'QUEUE_POSITION' | translate:{ position: thirdPartyQueuePosition } }}
          </div>
          <div
            fxFlex="nogrow"
            fxLayout="row"
            fxLayoutAlign="stretch center"
            fxLayoutGap="0.25em"
            class="newMessage"
            *ngIf="!hideTextarea && !hideTextareaUntilButtonClick"
            [ngClass]="{ isContactForm: chatMode === 'form' }"
          >
            <!--
              ⚠️ INFO:
              The `textarea-container` div is required because the `autosize` directive doesn't
              compute the correct height of the textarea when setting a vertical padding to it.
            -->
            <div
              fxFlex="grow"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="textarea-container"
              (click)="focusTextarea($event)"
            >
              <textarea
                fxFlex="grow"
                type="text"
                maxlength="512"
                spellcheck="false"
                autosize
                #newMessageInput
                [minRows]="1"
                [maxRows]="5"
                [placeholder]="'TYPE_HERE' | translate"
                [(ngModel)]="newMessage"
                (keydown.enter)="textareaEnter($event)"
                (keyup.enter)="newMessage && sendMessage()"
                (resized)="scrollDown()"
              ></textarea>
            </div>
            <label
              fxFlex="nogrow"
              for="open-file"
              [ngStyle]="{ color: displayService.chatStyle.sendButtonColor }"
              *ngIf="!isLoading"
            >
              <rmx-icon name="attachment-line" class="s-1x"></rmx-icon>
            </label>
            <div *ngIf="isLoading" class="lds"><div [ngStyle]="{ background: displayService.chatStyle.sendButtonColor }"></div><div div [ngStyle]="{ background: displayService.chatStyle.sendButtonColor }"></div><div div [ngStyle]="{ background: displayService.chatStyle.sendButtonColor }"></div></div>
            <input
              fxFlex="nogrow"
              type="file"
              id="open-file"
              accept="image/*,application/pdf, video/mp4"
              (change)="fileInput($event)"
              *ngIf="!isLoading"
            />
            <rmx-icon
              fxFlex="nogrow"
              name="send-plane-2-line"
              class="s-1x"
              *ngIf="newMessage && newMessage.trim() !== ''"
              @slideSendButton
              [ngStyle]="{ color: displayService.chatStyle.sendButtonColor }"
              (click)="sendMessage()"

            ></rmx-icon>
          </div>
          <div
            fxFlex="nogrow"
            *ngIf="!selfCareEnabled && chatMode !== 'form'"
            class="poweredBy"
            [ngClass]="{ removeReferral: displayService.removeReferral }"
          >
            <a *ngIf="displayService.referralIsLink" [href]="referralLink.href" target="_blank">
              <span>Powered by</span>
              <img src="assets/logo_and_name.png" width="60px" height="25px" />
            </a>
            <span *ngIf="!displayService.referralIsLink">
              <span>Powered by</span>
              <img src="assets/logo_and_name.png" width="60px" height="25px" />
            </span>
          </div>
          <div
            fxFlex="nogrow"
            *ngIf="selfCareEnabled || chatMode === 'form'"
            class="poweredBy"
            [ngClass]="{ removeReferral: displayService.removeReferral }"
          >
            <a *ngIf="displayService.referralIsLink" [href]="referralLink.href" target="_blank">
              <span>Powered by</span>
              <img src="assets/logo_and_name.png" width="60px" height="25px" />
            </a>
            <span *ngIf="!displayService.referralIsLink">
              <span>Powered by</span>
              <img src="assets/logo_and_name.png" width="60px" height="25px" />
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'form'">
      <!-- Header -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="header"
        [ngStyle]="displayService.chatStyle.headerStyle"
      >
        <span fxFlex="nogrow" [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo">
          {{ displayService.chatStyle.headerText.title || '' }}
        </span>

        <div
          fxFlex="nogrow"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="15px"
          class="header-buttons"
        >
          <rmx-icon
            fxFlex="nogrow"
            name="home-2-line"
            class="s-xl"
            [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
            (click)="selfCareResetAsk()"
          ></rmx-icon>

          <rmx-icon
            fxFlex="nogrow"
            name="subtract-line"
            class="s-2x"
            *ngIf="!displayService.freeDivMode"
            [ngStyle]="displayService.chatStyle.headerStyle.textAndLogo"
            (click)="toggleChatStatus(true)"
          ></rmx-icon>
        </div>
      </div>

      <!-- Content -->
      <div
        fxLayout="column"
        fxLayoutAlign="center stretch"
        class="main"
        [ngStyle.lt-sm]="{ 'padding.px': '0', 'padding-left.px': '15' }"
        [checkFiles]="checkFiles"
        (appDropZone)="onDrop($event)"
      >
        <span
          fxFlex="nogrow"
          class="subtitle"
          *ngIf="
            displayService.chatStyle.headerText &&
            !!displayService.chatStyle.headerText.text &&
            expandHeader
          "
        >
          {{ displayService.chatStyle.headerText.text }}
        </span>

        <ng-scrollbar fxFlex="nogrow" [ngStyle.lt-sm]="{ 'padding-right.px': '15' }">
          <app-message
            @showHideBotMessages
            *ngFor="
              let message of shownMessages;
              let i = index;
              let isFirst = first;
              let isLast = last;
              trackBy: trackMessageBy
            "
            [ngStyle]="{
              'padding-top':
                !isFirst && (message.type === 'buttons' || !precededBySameAuthor(i))
                  ? '15px'
                  : null,
              'max-width':
                !isVisitor(shownMessages[i]) && message.type === 'buttons' ? '100%' : '85%',
              'margin-left': isVisitor(shownMessages[i]) ? 'auto' : null,
              'text-align': isVisitor(shownMessages[i]) ? 'end' : 'start'
            }"
            [ngStyle.lt-sm]="{
              'margin-top': isFirst ? '28px' : null,
              'margin-bottom': isLast ? '28px' : null
            }"
            [ngStyle.gt-xs]="{
              'margin-top': isFirst ? '87.5px' : null,
              'margin-bottom': isLast ? '87.5px' : null
            }"
            [widget]="this"
            [message]="message"
            [focused]="this.focusMessageIndex === i"
            [precededBySameAuthor]="precededBySameAuthor(i)"
            [followedBySameAuthor]="followedBySameAuthor(i)"
            [chatMode]="chatMode"
            [isLastMessage]="isLast"
            (click)="focusMessage(i)"
            (clickOutside)="unFocusMessage(i)"
            (openWebview)="showWebview($event)"
          ></app-message>
        </ng-scrollbar>

        <div
          fxFlex="nogrow"
          fxLayout="row"
          class="visitor-message"
          *ngIf="!hideTextarea && !hideTextareaUntilButtonClick"
        >
          <div
            fxFlex="grow"
            fxLayout="row"
            fxLayoutAlign="stretch center"
            fxLayoutGap="0.25em"
            class="newMessage"
            @showHideTextarea
            (click)="focusTextarea($event)"
          >
            <!--
            ⚠️ INFO:
            The `textarea-container` div is required because the `autosize` directive doesn't
            compute the correct height of the textarea when setting a vertical padding to it.
          -->
            <div
              fxFlex="grow"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="textarea-container"
              (click)="focusTextarea($event)"
            >
              <textarea
                fxFlex="grow"
                type="text"
                maxlength="512"
                spellcheck="false"
                autosize
                #newMessageInput
                [minRows]="1"
                [maxRows]="5"
                [placeholder]="'TYPE_HERE' | translate"
                [(ngModel)]="newMessage"
                (keydown.enter)="textareaEnter($event)"
                (keyup.enter)="newMessage && sendMessage()"
                (resized)="scrollDown()"
              ></textarea>
            </div>
            <label
              for="open-file"
              [ngClass]="{ writing: newMessage }"
              [ngStyle]="{ color: displayService.chatStyle.sendButtonColor }"
            >
              <rmx-icon name="attachment-line" class="s-1x"></rmx-icon>
            </label>
            <input
              type="file"
              id="open-file"
              accept="image/*,application/pdf"
              (change)="fileInput($event)"
            />
            <rmx-icon
              fxFlex="nogrow"
              name="send-plane-2-line"
              class="s-1x"
              *ngIf="newMessage !== null && newMessage !== ''"
              @slideSendButton
              [ngStyle]="{ color: displayService.chatStyle.sendButtonColor }"
              (click)="sendMessage()"
            >
            </rmx-icon>
          </div>
        </div>
      </div>

      <!-- Footer -->
    </ng-container>
  </div>

  <app-webview
    class="insideFrame"
    *ngIf="webviewUrl && webviewUrl !== ''"
    [urlToBeSanitized]="webviewUrl"
    (hide)="onCloseWebview()"
  ></app-webview>

</ng-container>

<app-rating-comment-form *ngIf="showRatingCommentForm"></app-rating-comment-form>

<!-- Modals -->
<jw-modal id="modal-confirm-reset">
  <div>
    <p style="text-align: center; font-weight: bold">
      {{ 'RESET_SC' | translate }}
    </p>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" id="modal-confirm-buttons">
      <button
        [ngStyle]="getButtonsItemStyle(0)"
        (click)="selfCareResetCancel()"
        (mouseover)="hoverInd = 0"
        (mouseleave)="hoverInd = -1"
      >
        {{ 'CANCEL' | translate }}
      </button>

      <button
        [ngStyle]="getButtonsItemStyle(1)"
        (click)="selfCareResetConfirm()"
        (mouseover)="hoverInd = 1"
        (mouseleave)="hoverInd = -1"
      >
        {{ 'CONFIRM' | translate }}
      </button>
    </div>
  </div>
</jw-modal>

<jw-modal id="modal-collect-consent" [sticky]="true">
  <div>
    <p class="consent-ctn" [innerHTML]="this.consentHtml"></p>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" id="modal-consent-buttons">
      <button
        fxFlex="nogrow"
        [ngStyle]="getButtonsItemStyle(2)"
        (click)="consentDenied()"
        (mouseover)="hoverInd = 2"
        (mouseleave)="hoverInd = -1"
      >
        {{ 'REFUSE' | translate }}
      </button>

      <button
        fxFlex="nogrow"
        [ngStyle]="getButtonsItemStyle(3)"
        (click)="consentAccepted()"
        (mouseover)="hoverInd = 3"
        (mouseleave)="hoverInd = -1"
      >
        {{ 'ACCEPT' | translate }}
      </button>
    </div>
  </div>
</jw-modal>
