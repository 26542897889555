import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularRemixIconComponent } from 'angular-remix-icon';
import { LoggerService } from '../services/logger/logger.service';
import { DisplayService } from '../services/display.service';

@Component({
  selector: 'app-rating-comment-form',
  templateUrl: 'rating-comment-form.component.html',
  styleUrls: ['rating-comment-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AngularRemixIconComponent, FormsModule, NgIf, NgStyle, TranslateModule],
})
export class RatingCommentFormComponent {
  @Input() id: string;
  @Input() additionalStyle: any = {};
  @Input() sticky: boolean = false;
  private element: HTMLElement;
  public rating: 'good' | 'bad' | null = null;
  public comment: string = '';
  public step: 'rating' | 'comment' = 'rating';

  public mouseOverSendButton = false;

  constructor(
    private logger: LoggerService,
    public displayService: DisplayService,
  ) {}

  onRate(rating: 'good' | 'bad'): void {
    this.rating = rating;
    this.step = 'comment';
    void this.displayService.widget.submitRating(this.rating);
  }

  onSubmitComment(): void {
    void this.displayService.widget.submitComment(this.comment);
    this.displayService.widget.dismissRatingCommentForm();
  }

  onDismiss(): void {
    this.displayService.widget.dismissRatingCommentForm();
  }

  getButtonStyle() {
    if (this.mouseOverSendButton) {
      return this.displayService.chatStyle.buttonsStyle.hoveredItem;
    }
    return this.displayService.chatStyle.buttonsStyle.item;
  }
}
