/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, map, first, debounceTime } from 'rxjs/operators';
import { User } from '../model/user';
import { ChatEventType, TriggerStatus, Session } from '../model/Session';
import { WidgetComponent } from '../widget/widget.component';
import { trimUrl } from '../misc/utilities';
import { Mutex } from '../misc/Mutex';
import { Message } from '../model/message';
import { WidgetChatStyle } from '../model/widgetChatStyle';
import { BackendService } from './backend.service';
import { PopTriggerService } from './pop-triggers.service';
import { LoggerService } from './logger/logger.service';

type ChatStatusType = 'open' | 'closed';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  public environment: string;

  private backendIsMobileSubject$: Subject<boolean> = new Subject();

  //* * Can be used by the app to be notified of switches between mobile / desktop mode */
  isMobile$: Subject<boolean> = new BehaviorSubject(false);

  public isStandalone: boolean = false;

  constructor(
    private logger: LoggerService,
    public backendService: BackendService,
    public popTriggerService: PopTriggerService,
  ) {
    this.backendIsMobileSubject$
      .pipe(
        debounceTime(1000),
        map((isMobile) => this.backendService.sendIsMobile(isMobile)),
      )
      .subscribe();

    parent.window.postMessage({ type: 'bc_initialized' }, '*');
    const listener = async (event: MessageEvent) => {
      if (!event.data.type) {
        return;
      }
      switch (event.data.type) {
        case 'bc_onpage':
          this.windowIsActive = true;
          this.updateNotificationStatus();
          break;
        case 'bc_initParams':
          this.spanButtonLabel = event.data.value?.buttonLabel;
          break;
        case 'bc_leftpage':
          this.windowIsActive = false;
          setTimeout(() => this.updateNotificationStatus(), 10);
          break;
        case 'bc_sendWidth':
          this.windowWidth = event.data.data;
          break;
        case 'bc_hrefChange':
          this.hrefChange(event.data.data);
          break;
        case 'bc_customEvent':
          Object.assign(event.data.data, {
            isMobile: this.isMobile,
            userAgent: window.navigator.userAgent,
            pageUrl: trimUrl(this.widget ? this.widget.parentPageUrl : undefined),
            pageTitle: this.widget ? this.widget.parentPageTitle : undefined,
            language: this.widget ? this.widget.language : undefined,
          });

          void this.backendService.logEvent(ChatEventType.CUSTOM_EVENT, undefined, event.data.data);

          break;
        case 'bc_orderValidatedEvent':
          if (this.widget) {
            Object.assign(event.data.data, {
              isMobile: this.isMobile,
              userAgent: window.navigator.userAgent,
              pageUrl: trimUrl(this.widget.parentPageUrl),
              pageTitle: this.widget.parentPageTitle,
              language: this.widget.language,
            });
            switch (this.widget.session.triggerStatus) {
              case TriggerStatus.ENGAGING:
              case TriggerStatus.WELCOMING_ENGAGING:
              case TriggerStatus.ENGAGED:
                if (this.widget.session.triggerData) {
                  Object.assign(event.data.data, {
                    triggerId: this.widget.session.triggerData.triggerId,
                  });
                }
                break;
              case TriggerStatus.WELCOMING:
                if (this.widget.session.welcomeTriggerData) {
                  Object.assign(event.data.data, {
                    triggerId: this.widget.session.welcomeTriggerData.triggerId,
                  });
                }
                break;
              default:
                event.data.data.triggerId = null;
                break;
            }
            if (typeof event.data.data.orderAmount !== 'number') {
              event.data.data.orderAmount = Number(event.data.data.orderAmount);
            }

            if (Number.isNaN(event.data.data.orderAmount)) {
              this.logger.error('orderAmount is Not a Number');
              return;
            }

            void this.backendService.logEvent(
              ChatEventType.ORDER_VALIDATED,
              event.data.data.transactionId,
              event.data.data,
            );

            this.pendingOrderValidationEventData = null;
          } else {
            this.pendingOrderValidationEventData = event.data.data;
          }
          break;
        case 'bc_setUser':
          await this.mutexSetUser.lock();
          if (!this.widget.ghostMode) {
            await this.backendService.setUserIdentity(event.data.data, this.widget.parentPageUrl).then((result) => {
              const user = new User(
                result.email,
                result.firstName,
                result.lastName,
                result.type,
                result.id,
                result.avatar,
                result.customData,
              );
              this.setUserSubject.next(user);
            });
          } else if (event.data.data.email) {
            await this.widget.leaveGhostMode().then(async () => {
              await this.backendService.setUserIdentity(event.data.data, this.widget.parentPageUrl).then((result) => {
                const user = new User(
                  result.email,
                  result.firstName,
                  result.lastName,
                  result.type,
                  result.id,
                  result.avatar,
                  result.customData,
                );
                this.setUserSubject.next(user);
              });
            });
          } else {
            this.widget.session.customer.firstName = event.data.data.firstName;
            this.widget.session.customer.lastName = event.data.data.lastName;
            if (event.data.data.customData?.length) {
              this.widget.session.customer.customData = event.data.data.customData;
            }
            const { customer } = this.widget.session;
            const user = new User(
              customer.email,
              customer.firstName,
              customer.lastName,
              customer.type,
              customer.id,
              customer.avatar,
              customer.customData,
            );
            this.setUserSubject.next(user);
          }
          this.mutexSetUser.unlock();
          break;
        case 'bc_resetUser':
          if (!this.widget.ghostMode) {
            await this.backendService.resetUser().then((result) => {
              const user = new User(
                result.email,
                result.firstName,
                result.lastName,
                result.type,
                result.id,
                result.avatar,
                result.customData,
              );
              this.setUserSubject.next(user);
            });
          } else {
            await this.backendService.resetGhostSession(this.widget.session).then(async (newSession) => {
              this.widget.messagesClear();
              this.widget.setSession(newSession);
              await this.backendService.getNewLead(newSession.id, newSession.accountId).then((customer) => {
                const user = new User(
                  customer.email,
                  customer.firstName,
                  customer.lastName,
                  customer.type,
                  customer.id,
                  customer.avatar,
                  customer.customData,
                );
                this.setUserSubject.next(user);
              });
            });
          }
          break;
        case 'bc_sendIsMobile':
          this.isMobileDisplay = event.data.data.isMobileDisplay;
          this.isMobile = event.data.data.isMobile;
          this.isMobile$.next(event.data.data.isMobile);
          if (!event.data.data.ghostMode) {
            this.backendIsMobileSubject$.next(event.data.data.isMobile);
          }
          break;
        case 'bc_consentCollected':
          await this.bcConsentCollectedEvent();
          break;
        case 'bc_consentReceivedOnInit':
          if (typeof event.data.data === 'string' && !!event.data.data) {
            void this.backendService.logEvent(ChatEventType.GDPR_ACCEPT_ON_INIT, `gdpr_accept-${event.data.data}`);
          }
          break;
        case 'bc_openWidgetGhost':
          {
            const open: boolean = event.data.data;

            await this.setChatStatus(open ? 'open' : 'closed', false, false, this.widget);
          }
          break;
        default:
      }
    };

    window.addEventListener('message', listener, false);

    window.addEventListener('focus', () => {
      this.widgetIsActive = true;
      this.updateNotificationStatus();
    });

    window.addEventListener('blur', () => {
      this.widgetIsActive = false;
      setTimeout(() => this.updateNotificationStatus(), 10);
    });
  }

  public newMessages = 0;

  public widgetIsActive = false;
  public windowIsActive = true;

  public shouldNotify = true;

  public chatIsAvailable = true;

  private _chatStatus: ChatStatusType;
  public chatStatus$: BehaviorSubject<ChatStatusType> = new BehaviorSubject('closed');
  public get chatStatus(): ChatStatusType {
    return this._chatStatus;
  }
  public set chatStatus(value: ChatStatusType) {
    this._chatStatus = value;
    this.chatStatus$.next(value);
  }

  public widgetOnRight = true;
  public fullHeight = false;
  public freeDivMode = false;
  public removeReferral = false;
  public referralIsLink = true;

  public windowWidth: number = 300;
  public isMobileDisplay = false;
  public isMobile = false;
  public spanButtonLabel = '';
  private mutexSetUser: Mutex = new Mutex();

  public openCloseSubject: Subject<'open' | 'closed'> = new Subject<'open' | 'closed'>();
  public launcherAnimationSubject: Subject<{ delay: number; url: string }> = new Subject<{
    delay: undefined;
    url: undefined;
  }>();

  public hrefChangedSubject: Subject<{ href: string; pageName: string }> = new Subject<{
    href: string;
    pageName: string;
  }>();

  public setUserSubject: Subject<User> = new Subject<User>();

  public chatStyle: WidgetChatStyle = {
    launcherButtonStyle: {},
    launcherAnimationUrl: undefined,
    launcherAnimationDelay: undefined,
    headerStyle: {
      background: '',
      textAndLogo: {
        color: '',
      },
    },
    welcomeHeaderStyle: {},
    bgStyle: {},
    messageStyle: {},
    visitorMessageStyle: {},
    headerText: {
      title: '',
      text: '',
    },
    popNotifStyle: {},
    buttonsStyle: {
      buttons: {},
      item: {},
      hoveredItem: {},
      itemContent: {},
      hoveredItemContent: {},
    },
    sendButtonColor: '',
    iframeOpenStyle: '',
    iframeOpenMobileStyle: '',
    iframeClosedStyle: '',
    iframeClosedMobileStyle: '',
    enableHeaderImage: null, // this shouldn't be null as this column has a "NOT NULL" constraint
    enableSchemaSatisfaction: null, // this shouldn't be null as this column has a "NOT NULL" constraint
    consentHtml: [],
  };

  public pendingOrderValidationEventData: any | null = null;
  public pendingBcConsentCollectedEvent: boolean = false;

  public onNewMessage(message: Message, dismissedPopUp: boolean) {
    if (this.chatIsAvailable && this.chatStatus === 'closed' && !message.noNotif) {
      this.newMessages += 1;
      if (message.popNotif && !dismissedPopUp && !this.isStandalone) {
        void this.popTriggerService.addMessage(message);
      }
      this.updateNewMessagesStatus();
    }
  }

  public widget: WidgetComponent | null = null;

  public async enable(
    newSession: Session,
    state: 'open' | 'closed',
    widget: WidgetComponent,
    launcherButtonStyle: any,
  ) {
    const isFirstEnable = !this.widget;
    this.widget = widget;
    if (state === 'open') {
      await this.openChat(false, widget, true);
    } else if (state === 'closed') {
      if (!this.isStandalone) await this.closeChat(false, true);
    }
    parent.window.postMessage(
      {
        type: 'bc_enable',
        data: {
          style: launcherButtonStyle.style ? launcherButtonStyle.style : '',
        },
      },
      '*',
    );
    if (state === 'open') {
      await this.openChat(false, widget, true);
    }
    if (this.chatStyle.launcherAnimationDelay && this.chatStyle.launcherAnimationUrl) {
      this.launcherAnimationSubject.next({
        delay: this.chatStyle.launcherAnimationDelay,
        url: this.chatStyle.launcherAnimationUrl,
      });
    }

    if (isFirstEnable) {
      if (this.pendingOrderValidationEventData) {
        Object.assign(this.pendingOrderValidationEventData, {
          isMobile: this.isMobile,
          userAgent: window.navigator.userAgent,
          pageUrl: trimUrl(this.widget.parentPageUrl),
          pageTitle: this.widget.parentPageTitle,
          language: this.widget.language,
        });
        if (newSession.triggerData) {
          Object.assign(this.pendingOrderValidationEventData, {
            triggerId: newSession.triggerData.triggerId,
          });
        }
        if (typeof this.pendingOrderValidationEventData.orderAmount !== 'number') {
          this.pendingOrderValidationEventData.orderAmount = Number(this.pendingOrderValidationEventData.orderAmount);
        }

        if (isNaN(this.pendingOrderValidationEventData.orderAmount)) {
          this.logger.error('orderAmount is Not a Number');
        } else {
          void this.backendService
            .logEvent(
              ChatEventType.ORDER_VALIDATED,
              this.pendingOrderValidationEventData.transactionId,
              this.pendingOrderValidationEventData,
            )
            .then(() => {
              this.pendingOrderValidationEventData = null;
            });
        }
      }
    }
  }

  public disable() {
    this.chatStatus = 'closed';
    this.newMessages = 0;
    this.updateNewMessagesStatus();
    if (this.chatStyle.launcherButtonStyle.style) {
      if (this.chatStyle.launcherButtonStyle.style === 'circle') {
        parent.window.postMessage({ type: 'bc_close_circle' }, '*');
      } else if (this.chatStyle.launcherButtonStyle.style === 'square') {
        parent.window.postMessage({ type: 'bc_close_square' }, '*');
      } else if (this.chatStyle.launcherButtonStyle.style === '') {
        parent.window.postMessage({ type: 'bc_close_circle' }, '*');
      }
    } else parent.window.postMessage({ type: 'bc_close' }, '*');
    parent.window.postMessage({ type: 'bc_disable' }, '*');
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public updateNotificationStatus() {
    this.shouldNotify = (!this.widgetIsActive && !this.windowIsActive) || this.chatStatus === 'closed';
  }

  public getlauncherButtonStyle() {
    let radius = '30';
    let background: any = {
      background: this.chatStyle.launcherButtonStyle.background,
    };
    if (this.chatStyle.launcherButtonStyle.style && this.chatStyle.launcherButtonStyle.style !== '') {
      if (this.chatStyle.launcherButtonStyle.style === 'circle') {
        radius = '30';
      } else radius = '15';
    }
    if (this.chatStyle.launcherButtonStyle.url && this.chatStyle.launcherButtonStyle.url !== '') {
      background = {
        'background-color': this.chatStyle.launcherButtonStyle.background,
        'background-image': `url("${this.chatStyle.launcherButtonStyle.url}")`,
        'background-size': 'cover',
        'background-position-x': 'center',
        'background-position-y': 'center',
        color: 'transparent',
      };
    }
    const def = {
      display: this.chatStatus === 'closed' ? 'block' : 'none',
      right: this.widgetOnRight ? '0px' : 'auto',
      left: !this.widgetOnRight ? '0px' : 'auto',
      borderRadius: `${radius}px`,
    };
    if (this.fullHeight) {
      if (this.widgetOnRight) {
        def.borderRadius = `${radius}px 0px 0px ${radius}px`;
      } else {
        def.borderRadius = `0px ${radius}px ${radius}px 0px`;
      }
    }
    return { ...def, ...background };
  }

  public getMessageStyle(isVisitorMessage: boolean, delivered: boolean) {
    return {
      ...{ opacity: delivered ? '1' : '0.8' },
      ...(isVisitorMessage ? this.chatStyle.visitorMessageStyle : this.chatStyle.messageStyle),
    };
  }

  public getPopNotifStyle() {
    const width = Math.min(270, Math.round(this.windowWidth * 0.9));
    const def = {
      display: this.popTriggerService.messages.length && !this.isStandalone ? 'flex' : 'none',
      float: this.widgetOnRight ? 'right' : 'left',
      width: `${width}px`,
    };
    return def;
  }

  public async closeChat(isActorAction: boolean, updateSession: boolean) {
    if (this.chatStyle.launcherButtonStyle.style) {
      if (this.chatStyle.launcherButtonStyle.style === 'circle') {
        parent.window.postMessage({ type: 'bc_close_circle' }, '*');
      } else if (this.chatStyle.launcherButtonStyle.style === 'square') {
        parent.window.postMessage({ type: 'bc_close_square' }, '*');
      } else if (this.chatStyle.launcherButtonStyle.style === '') {
        parent.window.postMessage({ type: 'bc_close_circle' }, '*');
      }
    } else parent.window.postMessage({ type: 'bc_close' }, '*');
    const previousStatus = this.chatStatus;
    await this.sleep(1);
    this.chatStatus = 'closed';
    if (updateSession) {
      if (!this.widget.ghostMode) {
        await this.backendService.closeChat();
      }
      if (!this.widget.sessionSubscriptionActive) {
        this.widget.session.open = false;
      }
    }
    if (previousStatus !== 'closed') {
      if (isActorAction) {
        parent.window.postMessage(
          {
            type: 'bc_analytics',
            data: {
              type: 'Botmind_ChatClosed',
            },
          },
          '*',
        );
        if (updateSession) {
          void this.backendService.logEvent(ChatEventType.CLOSE);
        }
      }
    }
    this.updateNotificationStatus();
    return this.chatStatus;
  }

  public async openChat(isActorAction: boolean, widget: WidgetComponent, updateSession: boolean) {
    parent.window.postMessage({ type: 'bc_open' }, '*');
    const previousStatus = this.chatStatus;
    await this.sleep(1);
    this.chatStatus = 'open';
    if (updateSession) {
      if (!this.widget.ghostMode) {
        await this.backendService.openChat();
      }
      if (!this.widget.sessionSubscriptionActive) {
        this.widget.session.open = true;
        if (this.widget.session.triggerStatus === TriggerStatus.WAITING) {
          this.widget.session.triggerStatus = TriggerStatus.ABORTED;
          this.widget.session.triggerData = null;
        }
      }
    }
    this.newMessages = 0;
    if (!this.isStandalone) this.popTriggerService.clearMessages();
    this.updateNewMessagesStatus();
    this.openCloseSubject.next(this.chatStatus);
    if (previousStatus !== 'open') {
      if (isActorAction) {
        parent.window.postMessage(
          {
            type: 'bc_analytics',
            data: {
              type: 'Botmind_ChatOpened',
            },
          },
          '*',
        );
        if (updateSession) {
          void this.backendService.logEvent(ChatEventType.OPEN, undefined, {
            isActorAction,
            isMobile: this.isMobile,
            userAgent: window.navigator.userAgent,
            pageUrl: trimUrl(widget.parentPageUrl),
            pageTitle: widget.parentPageTitle,
            language: widget.language,
            date: new Date(),
            isEngageOpen: [TriggerStatus.ENGAGING, TriggerStatus.WELCOMING_ENGAGING].includes(
              widget.session.triggerStatus,
            ),
            actorId: widget.session.customer ? widget.session.customer.id : 'act_00000000-0000-0000-0000-000000000000',
          });
        }
      }
    }
    this.updateNotificationStatus();
    return this.chatStatus;
  }

  public async toggleChatStatus(isActorAction: boolean, widget: WidgetComponent): Promise<'open' | 'closed'> {
    if (this.chatStatus === 'open') {
      if (!this.isStandalone) return this.closeChat(isActorAction, true);
      return 'open';
    }
    if (this.chatStatus === 'closed') {
      return this.openChat(isActorAction, widget, true);
    }
    throw new Error('Invalid chat status');
  }

  public async setChatStatus(
    newStatus: 'open' | 'closed',
    isActorAction: boolean,
    updateSession: boolean,
    widget: WidgetComponent,
  ) {
    if (this.chatStatus === 'open' && newStatus === 'closed') {
      if (!this.isStandalone) {
        return this.closeChat(isActorAction, updateSession);
      }
      return 'open';
    }
    if (this.chatStatus === 'closed' && newStatus === 'open') {
      return this.openChat(isActorAction, widget, updateSession);
    }
    return newStatus;
  }

  public setEnvironment(environment: string) {
    this.environment = environment;
  }

  public updateNewMessagesStatus() {
    const message = { type: '' };
    parent.window.postMessage(this.newMessages ? { type: 'bc_newMessages' } : { type: 'bc_noNewMessages' }, '*');
    if (this.newMessages) {
      message.type = 'bc_newMessages';
    } else {
      message.type = 'bc_noNewMessages';
    }
    parent.window.postMessage(message, '*');
  }

  public refreshIframeStyle() {
    if (this.chatStyle.iframeOpenStyle) {
      parent.window.postMessage(
        {
          type: 'bc_set_iframe_open_css',
          data: this.chatStyle.iframeOpenStyle,
        },
        '*',
      );
    }
    if (this.chatStyle.iframeOpenMobileStyle) {
      parent.window.postMessage(
        {
          type: 'bc_set_iframe_open_mobile_css',
          data: this.chatStyle.iframeOpenMobileStyle,
        },
        '*',
      );
    }
    if (this.chatStyle.iframeClosedStyle) {
      parent.window.postMessage(
        {
          type: 'bc_set_iframe_closed_css',
          data: this.chatStyle.iframeClosedStyle,
        },
        '*',
      );
    }
    if (this.chatStyle.iframeClosedMobileStyle) {
      parent.window.postMessage(
        {
          type: 'bc_set_iframe_closed_mobile_css',
          data: this.chatStyle.iframeClosedMobileStyle,
        },
        '*',
      );
    }
  }

  public setIframeHeight(val: number) {
    parent.window.postMessage({ type: 'bc_setHeight', data: val }, '*');
  }

  public setIframeWidth(val: number) {
    parent.window.postMessage({ type: 'bc_setWidth', data: val }, '*');
  }

  public async popUpTriggerDismissed() {
    if (!this.widget.ghostMode) {
      await this.backendService.dismissPopUpTrigger();
    }
    if (!this.widget.sessionSubscriptionActive) {
      this.widget.session.dismissedPopUp = true;
    }
    if (!this.isStandalone) void this.closeChat(true, true);
  }

  public hrefChange(data: { href: string; pageName: string }) {
    this.hrefChangedSubject.next(data);
  }

  public getCustomerStorage(key: string) {
    const stamp = Date.now();
    parent.window.postMessage({ type: 'bc_getStorage', data: { key, stamp } }, '*');
    return fromEvent(window, 'message')
      .pipe(
        filter((event: MessageEvent) => {
          return event.data && event.data.type === 'bc_returnGet' && event.data.data.stamp === stamp;
        }),
        map((event: MessageEvent) => {
          return event.data.data.value;
        }),
        first(),
      )
      .toPromise();
  }

  public setCustomerStorage(key: string, value: any) {
    const stamp = Date.now();
    parent.window.postMessage({ type: 'bc_setStorage', data: { key, stamp, value } }, '*');
    return fromEvent(window, 'message')
      .pipe(
        filter((event: MessageEvent) => {
          return event.data && event.data.type === 'bc_returnSet' && event.data.data.stamp === stamp;
        }),
        map((event: MessageEvent) => {
          return event.data.data.value;
        }),
        first(),
      )
      .toPromise();
  }

  public removeCustomerStorage(key: string) {
    const stamp = Date.now();
    parent.window.postMessage({ type: 'bc_removeStorage', data: { key, stamp } }, '*');
    return fromEvent(window, 'message')
      .pipe(
        filter((event: MessageEvent) => {
          return event.data && event.data.type === 'bc_returnRemove' && event.data.data.stamp === stamp;
        }),
        map((event: MessageEvent) => {
          return event.data.data.value;
        }),
        first(),
      )
      .toPromise();
  }

  private async bcConsentCollectedEvent() {
    this.logger.debug('bcConsentCollectedEvent called');
    if (this.pendingBcConsentCollectedEvent && this.widget?.session) {
      await this.widget.leaveGhostMode().then(() => {
        if (this.widget?.session?.id && typeof this.widget.session.id === 'string') {
          void this.backendService.logEvent(
            ChatEventType.GDPR_ACCEPT_THROUGH_API,
            `gdpr_accept-${this.widget.session.id}`,
          );
        }
      });
      this.pendingBcConsentCollectedEvent = false;
    } else {
      this.pendingBcConsentCollectedEvent = true;
      window.setTimeout(() => {
        void this.bcConsentCollectedEvent();
      }, 200);
    }
  }
}
