<div class="content">
  <button class="close-button" (click)="onDismiss()">✖</button>

  <div *ngIf="step === 'rating'" class="inner-content">
    <div class="title">{{ 'RATING_FORM.RATING.TITLE' | translate }}</div>
    <div class="rating-buttons">
      <button class="good" (click)="onRate('good')" title="{{ 'RATING_FORM.RATING.GOOD' | translate }}">
        <rmx-icon name="thumb-up-line" class="s-xl"></rmx-icon>
      </button>
      <button class="bad" (click)="onRate('bad')" title="{{ 'RATING_FORM.RATING.BAD' | translate }}">
        <rmx-icon name="thumb-down-line" class="s-xl"></rmx-icon>
      </button>
    </div>
  </div>

  <div *ngIf="step === 'comment'" class="inner-content">
    <div class="title">{{ 'RATING_FORM.COMMENT.TITLE' | translate }}</div>
    <div class="comment-form">
      <textarea [(ngModel)]="comment" placeholder="{{ 'RATING_FORM.COMMENT.PLACEHOLDER' | translate }}"></textarea>
      <button
        [disabled]="comment === null && comment === ''"
        (mouseover)="mouseOverSendButton = true"
        (mouseleave)="mouseOverSendButton = false"
        [ngStyle]="getButtonStyle()"
        (click)="onSubmitComment()">
        {{ 'RATING_FORM.COMMENT.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</div>